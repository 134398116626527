import React, { useEffect } from "react";
// import Cookies from 'js-cookie'; // Import the js-cookie library
import axios from "axios";
// import '../App.css';
import { FiSettings } from "react-icons/fi";
import ChatWindow from "./ChatWindow";
import ConfigWindow from "./ConfigWindow";
import useChatBot from "./useChatBot";
import ChatSessionManager from "./ChatSessionManager";

axios.defaults.headers.common["x-requested-with"] = "anyValue";
const HEARTBEAT = process.env.REACT_APP_HEARTBEAT;
function TestingChatBot(props) {
  // State and other variables...

  const {
    chatMessages,
    userMessage,
    setUserMessage,
    token,
    setToken,
    companyId,
    setCompanyId,
    platformId,
    userId,
    digitalHumanId,
    privateMode,
    useCaseId,
    systemMessage,
    setSystemMessage,
    jsonError,
    showConfig,
    setShowConfig,
    isInputEnabled,
    agentName,
    userName,
    placeholder,
    agentTitle,
    isBotStarting,
    isAgentTyping,
    setPlatformId,
    setUserId,
    setDigitalHumanId,
    setPrivateMode,
    setUseCaseId,
    loading,
    hasChanges,
    setHasChanges,
    errors,
    handleSendMessage,
    handleMessageChange,
    setSession,
    avatarURL,
    setAvatarUrl,
    setRapid,
    onSessionEnd,
    sessionId,
    sessionInProgress,
    sendHeartbeat,
    setEndpoint,
    setIsSoundOn,
    isSoundOn,
    toggleSound,
    requestAudioAuthorization,
    modelProvider,
    setModelProvider,
    modelName,
    setModelName,
    systemInstruction,
    setSystemInstruction,
    variables,
    setVariables,
    customPayload,
    setCustomPayload,
    temperature,
    setTemperature,
  } = useChatBot(
    1,
    `{"first_name":"Jimmy","scheduled_time":"8:00am","arrival_time":"8:30am","next_shift":"January 29, 8:00am","streak":"17"}`,
    props.useCase,
    props.surveyLink,
    true
  );

  const headerText = props.header;
  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setHasChanges(true);
  };

  useEffect(() => {
    if (props.rapid) {
      setRapid(true);
    }
  });

  useEffect(() => {
    if (sessionInProgress) {
      const heartbeatInterval = setInterval(
        () => sendHeartbeat(sessionId),
        HEARTBEAT * 60 * 1000
      ); // Send heartbeat every 5 seconds

      return () => {
        clearInterval(heartbeatInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInProgress, sessionId]);

  return (
    <div className="app-container">
      <button className="gearbox" onClick={() => setShowConfig(!showConfig)}>
        <FiSettings />
      </button>

      <ConfigWindow
        showConfig={showConfig}
        token={token}
        setToken={setToken}
        handleInputChange={handleInputChange}
        handleMessageChange={handleMessageChange}
        companyId={companyId}
        setCompanyId={setCompanyId}
        platformId={platformId}
        setPlatformId={setPlatformId}
        userId={userId}
        digitalHumanId={digitalHumanId}
        privateMode={privateMode}
        setUserId={setUserId}
        setDigitalHumanId={setDigitalHumanId}
        setPrivateMode={setPrivateMode}
        useCaseId={useCaseId}
        setUseCaseId={setUseCaseId}
        jsonError={jsonError}
        hasChanges={hasChanges}
        errors={errors}
        setSession={setSession}
        systemMessage={systemMessage}
        setSystemMessage={setSystemMessage}
        setUserMessage={setUserMessage}
        userMessage={userMessage}
        setEndpoint={setEndpoint}
        setIsSoundOn={setIsSoundOn}
        isSoundOn={isSoundOn}
        toggleSound={toggleSound}
        requestAudioAuthorization={requestAudioAuthorization}
        modelProvider={modelProvider}
        setModelProvider={setModelProvider}
        modelName={modelName}
        setModelName={setModelName}
        systemInstruction={systemInstruction}
        setSystemInstruction={setSystemInstruction}
        variables={variables}
        setVariables={setVariables}
        customPayload={customPayload}
        setCustomPayload={setCustomPayload}
        temperature={temperature}
        setTemperature={setTemperature}
      />
      <ChatSessionManager
        onSessionEnd={onSessionEnd}
        sessionInProgress={sessionInProgress}
        sessionId={sessionId}
        userName={userName}
      />
      <ChatWindow
        chatMessages={chatMessages}
        handleSendMessage={handleSendMessage}
        loading={loading}
        userMessage={userMessage}
        setUserMessage={setUserMessage}
        testing={true}
        headerText={headerText}
        setSession={setSession}
        isInputEnabled={isInputEnabled}
        agent_name={agentName}
        user_name={userName}
        placeholder={placeholder}
        isBotStarting={isBotStarting}
        isAgentTyping={isAgentTyping}
        avatarURL={avatarURL}
        setAvatarUrl={setAvatarUrl}
        rapid={props.rapid}
        agentTitle={agentTitle}
        requestAudioAuthorization={requestAudioAuthorization}
        showTTSandSTTIcons={true}
      />
      {/* )} */}
    </div>
  );
}

export default TestingChatBot;
