import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ChatBot from "./ChatBot";
import ChatBotWrapper from "./ChatbotWrapper";
import TestingChatBot from "./TestingChatBot";
import ChatLog from "./ChatLog";
import SalesChatBotWrapper from "./SalesChatbotWrapper";
import PersonalAssistant from "./PersonalAssistant";
import { PromptVersionManager } from "./PromptVersions";
import DigitalHumanManager from "./DigitalHumanManager";

import "../App.css";

function App() {
  // Set the cookie with HttpOnly and Secure flags
  // Use state for arrival_time
  const arrivalTime = getTime("arrival_time");
  const scheduledTime = getTime("scheduled_time");
  const nextShift = getTime("next_shift");
  // const [arrivalTime, setArrivalTime] = React.useState(getTime('arrival_time'));
  // const [scheduledTime, setScheduledTime] = React.useState(getTime('scheduled_time'));
  // const [nextShift, setNextShift] = React.useState(getTime('next_shift'));

  const systemMessage2 = {
    first_name: "You",
    streak: 5,
    scheduled_time: scheduledTime,
    arrival_time: arrivalTime,
    next_shift: nextShift,
  };
  const systemMessage3 = {
    first_name: "You",
    streak: 5,
    scheduled_time: scheduledTime,
    arrival_time: arrivalTime,
    next_shift: nextShift,
  };
  const systemMessage4 = {
    first_name: "You",
    streak: 5,
    scheduled_time: scheduledTime,
    arrival_time: arrivalTime,
    next_shift: nextShift,
  };
  const systemMessage5 = {
    first_name: "You",
    streak: 5,
    scheduled_time: scheduledTime,
    arrival_time: arrivalTime,
    next_shift: nextShift,
  };
  const systemMessage6 = {
    first_name: "You",
    streak: 5,
    scheduled_time: scheduledTime,
    arrival_time: arrivalTime,
    next_shift: nextShift,
  };

  const useCase2SurveyLink = "https://mturk.com/return-to-survey-tab";
  const useCase3SurveyLink = "https://gmail.com";
  const useCase4SurveyLink = "https://youtube.com";
  const useCase5SurveyLink = "https://twitter.com";
  const useCase6SurveyLink = "https://apple.com";

  return (
    <Router>
      <Routes>
        <Route path="/" element={<GifPage header />} />
        <Route
          path="/test"
          element={
            <TestingChatBot
              header="Enter configuration variables to test your architecture"
              rapid={false}
            />
          }
        />
        <Route
          path="/rapidtest"
          element={
            <TestingChatBot
              header="Enter configuration variables to test your architecture"
              rapid={true}
            />
          }
        />
        <Route
          path="/test2"
          element={
            <ChatBot
              header="Pretend you are late for work. We are training junior managers to encourage employees to show up on time for work. In this chat session, you will pretend you are late for work. The trainee manager will confront you. Press the start button when you ready to begin."
              useCase="2"
              systemMessage={systemMessage2}
              surveyLink={useCase2SurveyLink}
            />
          }
        />
        <Route
          path="/test2911"
          element={
            <ChatBot
              header="Pretend you are late for work. We are training junior managers to encourage employees to show up on time for work. In this chat session, you will pretend you are late for work. The trainee manager will confront you. Press the start button when you ready to begin."
              useCase="2"
              systemMessage={systemMessage2}
              surveyLink={useCase2SurveyLink}
            />
          }
          cookieEnabled={true}
        />
        } />
        <Route
          path="/test2912"
          element={
            <ChatBot
              header="Pretend you are late for work. We have developed an AI chatbot to encourage employees to show up on time for work. In this chat session, you will pretend you are late for work. The AI chatbot will confront you. Press the start button when you ready to begin."
              useCase="2"
              systemMessage={systemMessage2}
              surveyLink={useCase2SurveyLink}
            />
          }
          cookieEnabled={true}
        />
        } />
        <Route
          path="/test3"
          element={
            <ChatBot
              header="Pretend you are early for work. We are training junior managers to encourage employees to show up on time. In this chat session, you will pretend you are early for work in a quick chat with our manager trainee. Press the start button when you ready to begin."
              useCase="3"
              systemMessage={systemMessage3}
              surveyLink={useCase3SurveyLink}
            />
          }
        />
        <Route
          path="/test3911"
          element={
            <ChatBot
              header="Pretend you are early for work. We are training junior managers to encourage employees to show up on time. In this chat session, you will pretend you are early for work in a quick chat with our manager trainee. Press the start button when you ready to begin."
              useCase="3"
              systemMessage={systemMessage3}
              surveyLink={useCase3SurveyLink}
            />
          }
          cookieEnabled={true}
        />
        } />
        <Route
          path="/test3912"
          element={
            <ChatBot
              header="Pretend you are early for work. We are training junior managers to encourage employees to show up on time. In this chat session, you will pretend you are early for work in a quick chat with our manager trainee. Press the start button when you ready to begin."
              useCase="3"
              systemMessage={systemMessage3}
              surveyLink={useCase3SurveyLink}
            />
          }
          cookieEnabled={true}
        />
        } />
        <Route
          path="/test4"
          element={
            <ChatBot
              header="Pretend you are on time for work. We are training junior managers to encourage employees to show up on time for work. In this chat session, you will pretend you are on time for work. The trainee manager will chat with you. Press the start button when you ready to begin."
              useCase="4"
              systemMessage={systemMessage4}
              surveyLink={useCase4SurveyLink}
            />
          }
        />
        <Route
          path="/test4911"
          element={
            <ChatBot
              header="Pretend you are on time for work. We are training junior managers to encourage employees to show up on time. You will have a quick chat with the training, pretending you are on time for work. Press the start button when you ready to begin."
              useCase="4"
              systemMessage={systemMessage4}
              surveyLink={useCase4SurveyLink}
            />
          }
          cookieEnabled={true}
        />
        } />
        <Route
          path="/test4912"
          element={
            <ChatBot
              header="Pretend you are on time for work. We have developed an AI chatbot to encourage employees to show up on time. You will have a quick chat with the chatbot, pretending you are on time for work. Press the start button when you ready to begin."
              useCase="4"
              systemMessage={systemMessage4}
              surveyLink={useCase4SurveyLink}
            />
          }
          cookieEnabled={true}
        />
        } />
        <Route
          path="/test5"
          element={
            <ChatBot
              header="You have a habit of checking-in on time for work!"
              useCase="5"
              systemMessage={systemMessage5}
              surveyLink={useCase5SurveyLink}
              cookieEnabled={true}
            />
          }
        />
        <Route
          path="/test6"
          element={
            <ChatBot
              header="You have a bad habit of checking-in late for work"
              useCase="6"
              systemMessage={systemMessage6}
              surveyLink={useCase6SurveyLink}
              cookieEnabled={true}
            />
          }
        />
        <Route
          path="/test11"
          element={
            <ChatBot
              header="You are late for work! Try to throw the AI Chatbot off track and see how he handles your trickery"
              useCase="11"
              systemMessage={systemMessage5}
              surveyLink={useCase5SurveyLink}
              cookieEnabled={false}
            />
          }
        />
        <Route
          path="/appointments/:appointmentId"
          element={<ChatBotWrapper systemMessage={{}} />}
        />
        <Route
          path="/sma-sales"
          element={<SalesChatBotWrapper systemMessage={{}} />}
        />
        <Route path="/chat-log" element={<ChatLog systemMessage={{}} />} />
        <Route path="/prompt-versions" element={<PromptVersionManager />} />
        <Route
          path="/personal-assistant"
          element={
            <PersonalAssistant
              header="Personal Assistant Chat"
              useCaseConfig={{
                company_id: 1,
                user_id: 1,
              }}
              agentName="George"
              agentTitle="Your Friendly Helper"
            />
          }
        />
        <Route
          path="/digital-human-manager"
          element={<DigitalHumanManager />}
        />
      </Routes>
    </Router>
  );
}

function GifPage() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img src="/TrueTime_PreLoader_Transparent.gif" alt="Loading..." />
    </div>
  );
}

export default App;

function getTime(type) {
  const date = new Date(); // Replace this with your Date object
  let hour = date.getHours();
  let amOrPm = "AM";

  if (hour >= 12) {
    amOrPm = "PM";
    if (hour > 12) {
      hour -= 12;
    }
  }

  if (type === "arrival_time") {
    return `${hour}:${String(date.getMinutes()).padStart(2, "0")} ${amOrPm}`;
  } else if (type === "scheduled_time") {
    return `${hour}:00 ${amOrPm}`;
  } else if (type === "next_shift") {
    // Calculate the next day's date
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);

    // Get the hour and minutes from the scheduled_time
    const scheduledHour = hour;

    return `${nextDay.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
    })} at ${scheduledHour}:00 ${amOrPm}`;
  }
}
