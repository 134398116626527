import React, { useState, useEffect } from "react";

function ConfigWindow({
  showConfig,
  token,
  setToken,
  handleInputChange,
  companyId,
  setCompanyId,
  platformId,
  setPlatformId,
  userId,
  digitalHumanId,
  privateMode,
  setUserId,
  setDigitalHumanId,
  setPrivateMode,
  useCaseId,
  setUseCaseId,
  jsonError,
  hasChanges,
  handleKeyValueChange,
  handleRemoveKeyValuePair,
  handleAddKeyValuePair,
  keyValuePairs,
  errors,
  setSession,
  systemInputValues,
  handleMessageChange,
  setSystemMessage,
  systemMessage,
  userMessage,
  setUserMessage,
  setEndpoint,
  setIsSoundOn,
  isSoundOn, // New prop for TTS state
  requestAudioAuthorization,
  toggleSound,
  modelProvider,
  setModelProvider,
  modelName,
  setModelName,
  systemInstruction,
  setSystemInstruction,
  variables,
  setVariables,
  customPayload,
  setCustomPayload,
  setTemperature,
  temperature,
}) {
  const [activeTab, setActiveTab] = useState("Agent");
  const [shouldStartSession, setShouldStartSession] = useState(false);
  const [customModels, setCustomModels] = useState({});
  const [newModelName, setNewModelName] = useState("");

  useEffect(() => {
    // Load custom models from localStorage
    const savedCustomModels =
      JSON.parse(localStorage.getItem("customModels")) || {};
    setCustomModels(savedCustomModels);
  }, []);
  // Add this function to update model names based on provider
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getModelOptions = (provider) => {
    const defaultOptions = {
      OpenAI: [
        "gpt-4o",
        "gpt-4o-2024-05-13",
        "gpt-4o-2024-08-06",
        "gpt-4o-mini",
        "gpt-4o-mini-2024-07-18",
      ],
      Anthropic: ["claude-3-5-sonnet-20240620", "claude-3-opus-20240229"],
    };

    const customProviderModels = customModels[provider] || [];
    return [...defaultOptions[provider], ...customProviderModels];
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const modelOptions = getModelOptions(modelProvider);
    setModelName(modelOptions[0] || "");
  }, [modelProvider]);

  const backendApi = process.env.REACT_APP_API_BACKEND_URL;

  const handleAddCustomModel = () => {
    if (newModelName.trim()) {
      const updatedCustomModels = {
        ...customModels,
        [modelProvider]: [
          ...(customModels[modelProvider] || []),
          newModelName.trim(),
        ],
      };
      setCustomModels(updatedCustomModels);
      localStorage.setItem("customModels", JSON.stringify(updatedCustomModels));
      setNewModelName("");
    }
  };

  const fetchImage = async () => {
    try {
      const response = await fetch(`${backendApi}/usecasehandler/${useCaseId}`);
      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      window.open(objectURL, "_blank");
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const startConversation = async () => {
    let endpoint;
    let payload;

    if (activeTab === "Agent") {
      endpoint = "usecasehandler";
      setEndpoint(endpoint);
      setShouldStartSession(true);
      setCustomPayload({
        chat_type: "discrete",
      });
    } else if (activeTab === "Multi-Agent") {
      endpoint = "multi-agent";
      setEndpoint(endpoint);
      setShouldStartSession(true);
      setCustomPayload({
        chat_type: "discrete",
      });
    } else if (activeTab === "Sidekick") {
      endpoint = "digital_human";
      setEndpoint(endpoint);
      setShouldStartSession(true);
      setCustomPayload({
        chat_type: "sidekick",
        digital_human_user_id: digitalHumanId,
        private_mode: privateMode,
      });
    } else if (activeTab === "Non-Discrete") {
      endpoint = "non-discrete";
      payload = {
        model_provider: modelProvider,
        model_name: modelName,
        system_instruction: systemInstruction,
        user_input: userMessage,
        variables: variables,
        temperature: temperature,
        chat_type: "non-discrete",
      };
      setCustomPayload(payload);
      setEndpoint(endpoint);
      setShouldStartSession(true);
    } else if (activeTab === "file-search") {
      endpoint = "file-search";
      setEndpoint(endpoint);
      console.log("endpoint", endpoint);
      setShouldStartSession(true);
      setCustomPayload({
        chat_type: "file-search",
      });
    }
  };

  const extractVariables = (instruction) => {
    const regex = /{{(.*?)}}/g;
    const matches = instruction.match(regex) || [];
    const newVariables = {};
    matches.forEach((match) => {
      const variable = match.replace(/{{|}}/g, "").trim();
      newVariables[variable] = variables[variable] || "";
    });
    setVariables(newVariables);
  };

  useEffect(() => {
    if (shouldStartSession) {
      setSession();
      setShouldStartSession(false);
    }
  }, [setEndpoint, shouldStartSession, setSession]);

  useEffect(() => {
    extractVariables(systemInstruction);
  }, [systemInstruction]);

  if (!showConfig) return null;

  return (
    <div className="config-window">
      <h2>Configuration Variables</h2>
      <div className="tabs">
        <button
          className={`tab ${activeTab === "Agent" ? "active" : ""}`}
          onClick={() => setActiveTab("Agent")}
        >
          Agent
        </button>
        <button
          className={`tab ${activeTab === "Multi-Agent" ? "active" : ""}`}
          onClick={() => setActiveTab("Multi-Agent")}
        >
          Multi-Agent
        </button>
        <button
          className={`tab ${activeTab === "Sidekick" ? "active" : ""}`}
          onClick={() => setActiveTab("Sidekick")}
        >
          Sidekick
        </button>
        {/* Add the new Non-Discrete tab button */}
        <button
          className={`tab ${activeTab === "Non-Discrete" ? "active" : ""}`}
          onClick={() => setActiveTab("Non-Discrete")}
        >
          Non-Discrete
        </button>
        <button
          className={`tab ${activeTab === "file-search" ? "active" : ""}`}
          onClick={() => {
            setActiveTab("file-search");
            setEndpoint("file-search");
          }}
        >
          NL-File
        </button>
      </div>
      <div className="config-inputs">
        {activeTab === "Agent" && (
          <div className="config-inputs">
            <div className="config-input">
              <label>Token:</label>
              <input
                type="text"
                value={token}
                onChange={handleInputChange(setToken)}
              />
              {errors.token && <small className="error">{errors.token}</small>}
            </div>
            <div className="config-input">
              <label>Company ID:</label>
              <input
                type="text"
                value={companyId}
                onChange={handleInputChange(setCompanyId)}
              />
              {errors.companyId && (
                <small className="error">{errors.companyId}</small>
              )}
            </div>
            <div className="config-input">
              <label>Platform ID:</label>
              <input
                type="text"
                value={platformId}
                onChange={handleInputChange(setPlatformId)}
              />
              {errors.platformId && (
                <small className="error">{errors.platformId}</small>
              )}
            </div>
            <div className="config-input">
              <label>User ID:</label>
              <input
                type="text"
                value={userId}
                onChange={handleInputChange(setUserId)}
              />
              {errors.userId && (
                <small className="error">{errors.userId}</small>
              )}
            </div>
            <div className="config-input">
              <label>Use Case ID:</label>
              <input
                type="text"
                value={useCaseId}
                onChange={handleInputChange(setUseCaseId)}
              />
              {errors.useCaseId && (
                <small className="error">{errors.useCaseId}</small>
              )}
            </div>
            <div className="config-input">
              <label>Message (JSON):</label>
              <textarea
                value={systemMessage}
                onChange={handleInputChange(setSystemMessage)}
                rows="7"
              />
              {jsonError && <small className="error">{jsonError}</small>}
            </div>
          </div>
        )}
        {activeTab === "Multi-Agent" && (
          <div className="config-inputs">
            <div className="config-input">
              <label>User Id:</label>
              <input
                type="text"
                value={userId}
                onChange={handleInputChange(setUserId)}
              />
            </div>
            <div className="config-input">
              <label>System Message (JSON):</label>
              <textarea
                value={systemMessage}
                onChange={handleInputChange(setSystemMessage)}
                rows="7"
              />
              {jsonError && <small className="error">{jsonError}</small>}
            </div>
            <div className="config-input">
              <label>User Input:</label>
              <input
                type="text"
                value={userMessage}
                onChange={handleInputChange(setUserMessage)}
              />
            </div>
          </div>
        )}
        {activeTab === "Sidekick" && (
          <div className="config-inputs">
            <div className="config-input">
              <label>User Id:</label>
              <input
                type="text"
                value={userId}
                onChange={handleInputChange(setUserId)}
              />
            </div>
            <div className="config-input">
              <label>Digital Human User Id:</label>
              <input
                type="text"
                value={digitalHumanId}
                onChange={handleInputChange(setDigitalHumanId)}
              />
            </div>
            <div className="config-input">
              <label>Private mode:</label>
              <input
                type="checkbox"
                checked={privateMode}
                onChange={(e) => setPrivateMode(e.target.checked)}
              />
            </div>
            <div className="config-input">
              <label>User Input:</label>
              <input
                type="text"
                value={userMessage}
                onChange={handleInputChange(setUserMessage)}
              />
            </div>
            <button
              onClick={() => (window.location.href = "/digital-human-manager")}
              className="save-btn"
              style={{ marginTop: "10px" }}
            >
              Go to Digital Human Manager
            </button>
          </div>
        )}
        {activeTab === "Non-Discrete" && (
          <div className="config-inputs">
            <div className="config-input">
              <label>Model Provider:</label>
              <select
                value={modelProvider}
                onChange={(e) => setModelProvider(e.target.value)}
              >
                <option value="OpenAI">OpenAI</option>
                <option value="Anthropic">Anthropic</option>
              </select>
            </div>
            <div className="config-input">
              <label>Model Name:</label>
              <select
                value={modelName}
                onChange={(e) => setModelName(e.target.value)}
              >
                {getModelOptions(modelProvider).map((model) => (
                  <option key={model} value={model}>
                    {model}
                  </option>
                ))}
              </select>
            </div>
            <div className="config-input">
              <label>Add Custom Model:</label>
              <input
                type="text"
                value={newModelName}
                onChange={(e) => setNewModelName(e.target.value)}
                placeholder="Enter custom model name"
              />
              <button onClick={handleAddCustomModel}>Add Model</button>
            </div>
            <div className="config-input">
              <label>System Instruction:</label>
              <textarea
                value={systemInstruction}
                onChange={(e) => setSystemInstruction(e.target.value)}
                rows="5"
              />
            </div>
            {Object.entries(variables).map(([key, value]) => (
              <div key={key} className="config-input">
                <label>{key}:</label>
                <input
                  type="text"
                  value={value}
                  onChange={(e) =>
                    setVariables({ ...variables, [key]: e.target.value })
                  }
                />
              </div>
            ))}
            <div className="config-input temperature-input">
              <label>Temperature:</label>
              <div className="temperature-controls">
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={temperature}
                  onChange={(e) => setTemperature(parseFloat(e.target.value))}
                />
                <input
                  type="number"
                  min="0"
                  max="1"
                  step="0.01"
                  value={temperature}
                  onChange={(e) => setTemperature(parseFloat(e.target.value))}
                />
              </div>
            </div>
            {/* <div className="config-input">
              <label>User Input:</label>
              <input
                type="text"
                value={userMessage}
                onChange={handleInputChange(setUserMessage)}
              />
            </div> */}
          </div>
        )}
      </div>
      <div className="buttons-container">
        <button
          className={`save-btn ${hasChanges ? "set-session" : ""}`}
          onClick={startConversation}
        >
          Start Conversation
        </button>
        <button
          className="get-diagram-btn"
          onClick={fetchImage}
          title="Fetch Diagram"
        >
          Get Diagram
        </button>
        {/* <button className="save-btn" onClick={toggleSound}>
                    {isSoundOn ? 'Disable TTS' : 'Enable TTS'}
                </button> */}
        {/* <button onClick={requestAudioAuthorization}>
          {isSoundOn ? "Disable TTS" : "Enable TTS"}
        </button> */}
      </div>
    </div>
  );
}

export default ConfigWindow;
