import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Stack,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const backendApi = process.env.REACT_APP_API_BACKEND_URL;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ marginTop: "16px" }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function DigitalHumanManager() {
  const [tabValue, setTabValue] = useState(0);
  const [digitalHumans, setDigitalHumans] = useState([]);
  const [skills, setSkills] = useState([]);
  const [editDHOpen, setEditDHOpen] = useState(false);
  const [editDHFields, setEditDHFields] = useState({
    id: null,
    title: "",
    default_language: "",
    active: false,
    personality_traits: "",
    avatar_url: "",
  });

  const [newSkill, setNewSkill] = useState({ name: "", description: "" });
  const [newAction, setNewAction] = useState({
    name: "",
    function_name: "",
    skill_id: "",
  });
  const [newAssignment, setNewAssignment] = useState({
    dh_profile_id: "",
    skill_id: "",
    action_id: "",
    function_name: "",
    public_action: true,
  });

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(`${backendApi}/digital-humans-skills/`);
      setSkills(response.data);
    } catch (error) {
      console.error("Error fetching skills:", error);
    }
  };

  const fetchDigitalHumans = async () => {
    try {
      const response = await axios.get(`${backendApi}/digital-humans/`);
      setDigitalHumans(response.data);
    } catch (error) {
      console.error("Error fetching digital humans:", error);
    }
  };

  const handleAddSkill = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${backendApi}/digital-humans-skills/`, {
        type: "skill",
        ...newSkill,
      });
      setNewSkill({ name: "", description: "" });
      fetchSkills();
    } catch (error) {
      console.error("Error adding skill:", error);
    }
  };

  const handleAddAction = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${backendApi}/digital-humans-skills/`, {
        type: "action",
        ...newAction,
      });
      setNewAction({ name: "", function_name: "", skill_id: "" });
      fetchSkills();
    } catch (error) {
      console.error("Error adding action:", error);
    }
  };

  const handleAssignSkill = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${backendApi}/digital-humans/assign/`, {
        ...newAssignment,
        active: true,
      });
      setNewAssignment({
        dh_profile_id: "",
        skill_id: "",
        action_id: "",
        function_name: "",
        public_action: true,
      });
      fetchDigitalHumans();
    } catch (error) {
      console.error("Error assigning skill:", error);
      alert("Error assigning skill. Please try again.");
    }
  };

  const handleEditDH = (dh) => {
    setEditDHFields({
      id: dh.id,
      title: dh.title,
      default_language: dh.default_language,
      active: dh.active,
      personality_traits: dh.personality_traits || "",
      avatar_url: dh.avatar_url || "",
    });
    setEditDHOpen(true);
  };

  const saveEditDH = async () => {
    try {
      // Assuming a PATCH or PUT endpoint to update digital human
      await axios.patch(`${backendApi}/digital-humans/${editDHFields.id}/`, {
        title: editDHFields.title,
        default_language: editDHFields.default_language,
        active: editDHFields.active,
        personality_traits: editDHFields.personality_traits,
        avatar_url: editDHFields.avatar_url,
      });
      setEditDHOpen(false);
      fetchDigitalHumans();
    } catch (error) {
      console.error("Error updating digital human:", error);
    }
  };

  useEffect(() => {
    fetchDigitalHumans();
    fetchSkills();
  }, []);

  // DataGrid columns for Digital Humans
  const dhColumns = [
    { field: "title", headerName: "Name", width: 150 },
    { field: "default_language", headerName: "Language", width: 120 },
    {
      field: "active",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <span
          style={{
            fontWeight: 600,
            color: params.value ? "green" : "red",
          }}
        >
          {params.value ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <IconButton
            onClick={() => handleEditDH(params.row)}
            size="small"
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton variant="outlined" color="error" size="small">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  // Helper: For skills and actions section, find which digital humans have a given action
  const getAssignedDHForAction = (actionId) => {
    const assigned = [];
    digitalHumans.forEach((dh) => {
      dh.skills?.forEach((s) => {
        s.actions?.forEach((a) => {
          if (a.action_id === actionId) {
            assigned.push(dh.title);
          }
        });
      });
    });
    return assigned;
  };

  // In the assignments tab, show currently assigned actions for the selected digital human (based on newAssignment.dh_profile_id)
  const currentDHAssignments = useMemo(() => {
    if (!newAssignment.dh_profile_id) return [];
    const dh = digitalHumans.find(
      (d) => d.id === Number(newAssignment.dh_profile_id)
    );
    if (!dh) return [];
    let assignedActions = [];
    dh.skills?.forEach((skill) => {
      console.log("skill", skill);
      skill.actions?.forEach((a) => {
        assignedActions.push({
          skill_name: skill.skill_name || skill.name, // skill_name or name from API
          action_id: a.action_id,
          action_name: a.action_name,
          public_action: a.public_action,
          function_name: a.function_name,
          skill_id: skill.skill_id,
        });
      });
    });
    return assignedActions;
  }, [newAssignment.dh_profile_id, digitalHumans]);

  // Group currentDHAssignments by skill_name
  const groupedAssignments = useMemo(() => {
    const groups = currentDHAssignments.reduce((acc, assignment) => {
      const { skill_name } = assignment;
      if (!acc[skill_name]) {
        acc[skill_name] = [];
      }
      acc[skill_name].push(assignment);
      return acc;
    }, {});
    return groups;
  }, [currentDHAssignments]);

  const toggleAssignmentPublic = async (action_id, makePublic, skill_id) => {
    try {
      const payload = {
        dh_profile_id: newAssignment.dh_profile_id,
        action_id,
        public_action: makePublic,
        active: true,
        skill_id: skill_id,
      };
      console.log("Request payload:", payload); // Add this line
      await axios.patch(`${backendApi}/digital-humans/assign/`, payload);
      fetchDigitalHumans();
    } catch (error) {
      console.error("Error toggling assignment:", error);
      alert("Error updating assignment. Please try again.");
    }
  };

  const deactivateAssignment = async (action_id) => {
    try {
      await axios.delete(`${backendApi}/digital-humans/unassign/`, {
        data: {
          dh_profile_id: newAssignment.dh_profile_id,
          action_id,
          active: false,
          skill_id: newAssignment.skill_id,
        },
      });
      fetchDigitalHumans();
    } catch (error) {
      console.error("Error deactivating assignment:", error);
      alert("Error deactivating assignment. Please try again.");
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Digital Human Manager
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabValue} onChange={handleChangeTab} aria-label="tabs">
          <Tab label="Digital Humans" {...a11yProps(0)} />
          <Tab label="Skills & Actions" {...a11yProps(1)} />
          <Tab label="Assignments" {...a11yProps(2)} />
        </Tabs>
      </Box>

      {/* DIGITAL HUMANS TAB */}
      <TabPanel value={tabValue} index={0}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">All Digital Humans</Typography>
          {/* <Button variant="contained" color="primary">
            + Create New
          </Button> */}
        </Box>
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={digitalHumans}
            columns={dhColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row.id}
          />
        </div>
      </TabPanel>

      {/* SKILLS & ACTIONS TAB */}
      <TabPanel value={tabValue} index={1}>
        <Typography variant="h6" gutterBottom>
          Manage Skills & Actions
        </Typography>

        <Box display="flex" flexWrap="wrap" gap={2} mb={4}>
          <Card sx={{ flex: "1 1 300px", maxWidth: 400 }}>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>
                Add a New Skill
              </Typography>
              <Box
                component="form"
                onSubmit={handleAddSkill}
                display="flex"
                flexDirection="column"
                gap={2}
              >
                <TextField
                  label="Skill Name"
                  value={newSkill.name}
                  onChange={(e) =>
                    setNewSkill({ ...newSkill, name: e.target.value })
                  }
                  required
                />
                <TextField
                  label="Description"
                  multiline
                  rows={3}
                  value={newSkill.description}
                  onChange={(e) =>
                    setNewSkill({ ...newSkill, description: e.target.value })
                  }
                />
                <Button type="submit" variant="contained" color="primary">
                  Add Skill
                </Button>
              </Box>
            </CardContent>
          </Card>

          <Card sx={{ flex: "1 1 300px", maxWidth: 400 }}>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>
                Add a New Action
              </Typography>
              <Box
                component="form"
                onSubmit={handleAddAction}
                display="flex"
                flexDirection="column"
                gap={2}
              >
                <TextField
                  label="Action Name"
                  value={newAction.name}
                  onChange={(e) =>
                    setNewAction({ ...newAction, name: e.target.value })
                  }
                  required
                />
                <TextField
                  label="Function Name"
                  value={newAction.function_name}
                  onChange={(e) =>
                    setNewAction({
                      ...newAction,
                      function_name: e.target.value,
                    })
                  }
                  required
                />
                <TextField
                  select
                  label="Associated Skill"
                  value={newAction.skill_id}
                  onChange={(e) =>
                    setNewAction({ ...newAction, skill_id: e.target.value })
                  }
                  required
                >
                  <MenuItem value="">Select Skill</MenuItem>
                  {skills.map((skill) => (
                    <MenuItem key={skill.id} value={skill.id}>
                      {skill.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Button type="submit" variant="contained" color="success">
                  Add Action
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Card>
          <CardContent>
            <Typography variant="subtitle1" gutterBottom>
              Existing Skills & Actions
            </Typography>
            {skills.length === 0 ? (
              <Typography color="text.secondary">
                No skills added yet.
              </Typography>
            ) : (
              <Box display="flex" flexWrap="wrap" gap={2}>
                {skills.map((skill) => (
                  <Paper key={skill.id} sx={{ p: 2, width: 300 }}>
                    <Typography variant="h6">{skill.name}</Typography>
                    <Typography variant="body2" color="text.secondary" mb={1}>
                      {skill.description}
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="subtitle2">Actions:</Typography>
                    {skill.actions && skill.actions.length > 0 ? (
                      skill.actions.map((action) => {
                        const assignedDH = getAssignedDHForAction(action.id);
                        return (
                          <Box
                            key={action.id}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              bgcolor: "#f9f9f9",
                              p: 1,
                              borderRadius: 1,
                              mt: 1,
                            }}
                          >
                            <Typography variant="body2" fontWeight="bold">
                              {action.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Function: {action.function_name}
                            </Typography>
                            {assignedDH.length > 0 && (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Assigned to: {assignedDH.join(", ")}
                              </Typography>
                            )}
                          </Box>
                        );
                      })
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No actions for this skill.
                      </Typography>
                    )}
                  </Paper>
                ))}
              </Box>
            )}
          </CardContent>
        </Card>
      </TabPanel>

      {/* ASSIGNMENTS TAB */}
      <TabPanel value={tabValue} index={2}>
        <Typography variant="h6" gutterBottom>
          Assign Skills & Actions to Digital Humans
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Box
                  component="form"
                  onSubmit={handleAssignSkill}
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  <TextField
                    select
                    label="Digital Human"
                    value={newAssignment.dh_profile_id}
                    onChange={(e) => {
                      setNewAssignment({
                        ...newAssignment,
                        dh_profile_id: e.target.value,
                      });
                    }}
                    required
                  >
                    <MenuItem value="">Select Digital Human</MenuItem>
                    {digitalHumans.map((dh) => (
                      <MenuItem key={dh.id} value={dh.id}>
                        {dh.title}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    label="Skill"
                    value={newAssignment.skill_id}
                    onChange={(e) =>
                      setNewAssignment({
                        ...newAssignment,
                        skill_id: e.target.value,
                        action_id: "",
                      })
                    }
                    required
                    disabled={!newAssignment.dh_profile_id}
                  >
                    <MenuItem value="">Select Skill</MenuItem>
                    {skills.map((skill) => (
                      <MenuItem key={skill.id} value={skill.id}>
                        {skill.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    label="Action"
                    value={newAssignment.action_id}
                    onChange={(e) =>
                      setNewAssignment({
                        ...newAssignment,
                        action_id: e.target.value,
                      })
                    }
                    required
                    disabled={!newAssignment.skill_id}
                  >
                    <MenuItem value="">Select Action</MenuItem>
                    {skills
                      .find((s) => s.id === Number(newAssignment.skill_id))
                      ?.actions?.map((action) => (
                        <MenuItem key={action.id} value={action.id}>
                          {action.name}
                        </MenuItem>
                      ))}
                  </TextField>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newAssignment.public_action}
                        onChange={(e) =>
                          setNewAssignment({
                            ...newAssignment,
                            public_action: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Public Action"
                  />

                  <Button type="submit" variant="contained" color="primary">
                    Assign
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Show existing assignments for the selected digital human, grouped by skill */}
          {newAssignment.dh_profile_id && (
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    Current Skill Action Assignments for{" "}
                    {
                      digitalHumans.find(
                        (d) => d.id === Number(newAssignment.dh_profile_id)
                      )?.title
                    }
                  </Typography>
                  {currentDHAssignments.length === 0 ? (
                    <Typography variant="body2" color="text.secondary">
                      No assigned actions.
                    </Typography>
                  ) : (
                    Object.entries(groupedAssignments).map(
                      ([skillName, actions]) => (
                        <Accordion key={skillName} disableGutters>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" fontWeight="bold">
                              {skillName}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List disablePadding>
                              {actions.map((assign, index) => {
                                console.log(
                                  "Assignment:",
                                  assign,
                                  "Index:",
                                  index
                                );
                                return (
                                  <ListItem
                                    key={`${skillName}-${assign.action_id}-${index}`}
                                    secondaryAction={
                                      <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                      >
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={assign.public_action}
                                              onChange={(e) =>
                                                toggleAssignmentPublic(
                                                  assign.action_id,
                                                  e.target.checked,
                                                  assign.skill_id
                                                )
                                              }
                                            />
                                          }
                                          label={
                                            assign.public_action
                                              ? "Public"
                                              : "Private"
                                          }
                                        />
                                        <IconButton
                                          edge="end"
                                          color="error"
                                          onClick={() =>
                                            deactivateAssignment(
                                              assign.action_id
                                            )
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Stack>
                                    }
                                  >
                                    <ListItemText
                                      primary={assign.action_name}
                                      secondary={
                                        <>
                                          Function: {assign.function_name}
                                          <br />
                                          {assign.public_action
                                            ? "Public"
                                            : "Private"}
                                        </>
                                      }
                                    />
                                  </ListItem>
                                );
                              })}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      )
                    )
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </TabPanel>

      {/* Edit Digital Human Dialog */}
      <Dialog
        open={editDHOpen}
        onClose={() => setEditDHOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Edit Digital Human</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Title"
              value={editDHFields.title}
              onChange={(e) =>
                setEditDHFields({ ...editDHFields, title: e.target.value })
              }
              fullWidth
            />
            <TextField
              label="Default Language"
              value={editDHFields.default_language}
              onChange={(e) =>
                setEditDHFields({
                  ...editDHFields,
                  default_language: e.target.value,
                })
              }
              fullWidth
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={editDHFields.active}
                  onChange={(e) =>
                    setEditDHFields({
                      ...editDHFields,
                      active: e.target.checked,
                    })
                  }
                />
              }
              label="Active"
            />
            <TextField
              label="Personality Traits"
              value={editDHFields.personality_traits}
              onChange={(e) =>
                setEditDHFields({
                  ...editDHFields,
                  personality_traits: e.target.value,
                })
              }
              multiline
              rows={3}
              fullWidth
            />
            <TextField
              label="Avatar URL"
              value={editDHFields.avatar_url}
              onChange={(e) =>
                setEditDHFields({ ...editDHFields, avatar_url: e.target.value })
              }
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDHOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={saveEditDH}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
